import './App.css';
import HomePage from './HomePage';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
  import { getFirestore} from 'firebase/firestore'
  const firebaseConfig = {
    apiKey: "AIzaSyCkEyE8Wl26CpASSG1f7fh9fSeroVFrJkU",
    authDomain: "somthing-c7852.firebaseapp.com",
    databaseURL: "https://somthing-c7852.firebaseio.com",
    projectId: "somthing-c7852",
    storageBucket: "somthing-c7852.appspot.com",
    messagingSenderId: "1051154063833",
    appId: "1:1051154063833:web:e42a257b16fdb20b2579c8",
    measurementId: "G-4DG56F235H"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  export const firestore = getFirestore(app)
  function App() {
  return (
    <div className="App">
    
    <HomePage />
    </div>
  );
}

export default App;
