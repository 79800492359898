
import React, {useState, useEffect}from 'react'
import logo from './images/LOGO.png'
import vector from './images/Vector.png'
import cart from './images/cart.svg'
import database from './images/database.svg'
import integration from './images/integration.svg'
import multi from './images/multi.svg'
import reporting from './images/reporting.svg'
import retargeting from './images/retargeting.svg'
import second from './images/second.svg'
import languageIcon from './images/languageIcon.png'
import {firestore} from './App'
import loadingImg from './images/loading.svg'
import Swal from 'sweetalert2'



import { addDoc, collection } from 'firebase/firestore'
// Define features array with objects containing image, title, and text
const features = [
 { img: integration, title: {EN:'Seamless Integration with Ecommerce platforms', AR:"سهولة الاقتران مع منصات التجارة الإلكترونية"}, text:{EN: ['Effortlessly integrate SMAC with your Sallah or Zid e-commerce site in just one click'],AR:["الدمج مع منصة التجارة الالكترونية الخاصة بك مثل سلة أو زد بنقرة واحدة مع SMAC. "] }},
 { img: cart, title: {EN:'Abandoned Cart Contact Automation', AR:"أتمتة عملية التذكير للعربات المتروكة"}, text:{EN: ['Automatically reach out to customers who leave without completing checkout.', 'Recover potentially lost sales by strategically following up with abandoned cart messages.'],AR:["	الوصول التلقائي لأي عميل قام باضافة مشتريات للعربة ولم يكمل عملية الدفع","المتابعة الاستراتيجية لرسائل سلة التسوق بهدف استرداد المبيعات المفقودة المحتملة"] }},
 { img: retargeting, title: {EN:'New Customer Engagement', AR:"التشارك مع العملاء الجدد"}, text:{EN: ['Welcome new store visitors with special discounts via eye-catching popup messages.', 'Automatically send welcome messages and special offers to convert first-time visitors into customers.'],AR:["الترحيب بالزوار الجدد وتقديم خصومات خاصة برسائل مميزة وجاذبة.","ارسال رسائل الترحيب والعروض الخاصة تلقائياً لتحويل اي زائر جديد لعميل."] }},
 { img: multi, title: {EN:'Multi-Channel Engagement', AR:"التشارك متعدد القنوات"}, text:{EN: ['Connect with customers through various communication channels, including text messages and emails.', 'Re-engage potential customers who abandon carts with targeted messages.'],AR:[" التواصل مع العملاء من خلال قنوات التواصل المختلفة، كالرسائل النصية ورسائل البريد الإلكتروني ورسائل الواتس آب","اعادة جذب العملاء المحتملين الذين تركوا عربات التسوق ذات الرسائل المستهدفة"] }},
 { img: retargeting, title: {EN:'Retargeting Campaigns', AR:"حملات إعادة الاستهداف"}, text:{EN: ["Implement automated campaigns to reactivate previous customers who haven't made a purchase in a while.","Achieve up to a 25% customer return rate with targeted retargeting efforts."],AR:["اجراء حملات تسويقية تستهدف العملاء السابقين الذين لم يقوموا بأي عمليات شراء منذ فترة لإعادة تنشيطهم","تحقيق الى نسبة 25% تنشيط للعملاء السابقين وإعادة تفعيل عمليات الشراء. "] }},
 { img: second, title: {EN:'Second Order Encouragement', AR:"التشجيع لتكرار عملية الطلب للمرة الثانية"}, text:{EN: ['Retarget new customers with enticing offers, encouraging them to place a second order.'],AR:["استهداف العملاء الجدد وارسال عروض مغرية لهم، بهدف حثهم للطلب مرة ثانية"] }},
 { img: database, title: {EN:'Database Management', AR:"إدارة قاعدة بياناتك"}, text:{EN: ['Build and segment your database effortlessly with automatic contact saving.', 'Streamline your communication efforts and enhance targeting precision.'],AR:["قم ببناء قاعدة بيانات خاصة بعملائك وقسمها من خلال الحفظ التلقائي لجهات الاتصال","تقليل المجهود المبذول لاجراء الاتصال بعملائك وتعزيز قوة ودقة استهداف الحملات التسويقية"] }},
 { img: reporting, title: {EN:'Comprehensive Reporting', AR:"التقارير الشاملة"}, text:{EN: ["Gain valuable insights into your customer's journey with detailed monthly and weekly reports.","Understand customer behavior at every stage, from initial contact to returns."],AR:["احصل على تقارير اسبوعية وشهرية تزودك بتطلعات ورؤى العملاء وخططهم الشرائية","فهم سلوك العملاء في جميع مراحل الشراء بدءا من الاتصال المبدئي وصولاً للمرتجعات"] }},
]

export default function HomePage() {
  const id = window.location.pathname
  const [lang, setLang] = useState(null)
  const [loading, setLoading] = useState(false)

  const changeLanguage = (language) => { 
    if(language.includes('-')){ 
        const langCode = language.split('-')
        language = langCode[0]

    }
    language = language.toUpperCase()
    if (!(language === 'AR' || language === 'EN')) {
      language = 'EN'
    }
    localStorage.setItem('langCode', language)
    setLang(language)
}
const [email, setEmail] = useState('')
const handleChange = (e)  => {
  setEmail(e.target.value)
}
const addEmail =async (e) => {
  e.preventDefault()
  try{ 
      if(email){
        setLoading(true)
        const currentdate = new Date(); 
  const datetime = currentdate.getDate() + "/"
  + (currentdate.getMonth()+1)  + "/" 
  + currentdate.getFullYear() + " @ "  
  + currentdate.getHours() + ":"  
  + currentdate.getMinutes() 
          await addDoc(collection(firestore, "emails"), {
              email, 
              time: datetime,
              id,
              lang
        })
        setEmail('')
        setLoading(false)
        Swal.fire({
          title: (lang === 'EN' ?"Email Received Successfully!": 'تم إرسال بريدك الإكتروني بنجاح'),
          htmlIcon: "!",
          text: (lang === 'EN' ? "Thank you. We will reach out to you soon.": "شكرا لك. سوف يتم التواصل معك في اقرب وقت ممكن"),
          icon: "success",
          confirmButtonColor: "#2f53d2",
        });
      } 
  }catch(err){
    console.log(err)
  }
}

const view =async (language) => { 
  let sent = false
if (!sent){
  sent = true
  const currentdate = new Date(); 
  const datetime = currentdate.getDate() + "/"
  + (currentdate.getMonth()+1)  + "/" 
  + currentdate.getFullYear() + " @ "  
  + currentdate.getHours() + ":"  
  + currentdate.getMinutes() 
  await addDoc(collection(firestore, "views"), {
    id,
    date: datetime,
    lang:language,
    user: navigator.userAgent
  })
} 
}


  useEffect(() => {
    const language = localStorage.getItem('langCode') || navigator.languages[0]
    view(language)
    changeLanguage(language)
 }
    , [])

 return ( 
     <> {lang? 
    <div className='homePage' style={lang !== "EN"?{textAlign:'right',direction:'rtl', fontFamily: "'Tajawal', sans-serif"}:{}}>
      {/* Header */}
      <div className="header--container">
        <div className="logo--container">
          <img src={logo} alt="" />
        </div>

        <div className='language--container'  onClick={()=>changeLanguage(lang === 'EN'?"AR":"EN")}> {lang !== "EN" ?"English":"العربية"} <div className='language--icon--container'><img style={lang !=="EN"?{marginTop:'-3px'}:{}} src={languageIcon} alt="" /></div></div>
      </div>

      {/* Title */}
      <h1 className="title">{lang==="EN"?"Strategic Marketing automation Company" :"شركة أتمتة التسويق الإلكتروني"}</h1>

      {/* Image */}
      <div className="image--container">
        <img src={vector} alt="" />
      </div>

      {/* Description */}
      <div className="description--container">
        <h3 className="description--title">
          {lang === 'EN'? "Unlock the power of your e-commerce potential!":"أظهر قوة امكانياتك في مجال التجارة الالكترونية! "}
         
        </h3>
        <p className='description--text'>
        {lang === 'EN'? "Imagine this: Only 2% of your website visitors are prepared to make an immediate purchase, while a significant 50% currently show no interest in your product. Now, consider the intriguing 48% in between—they express interest but aren't ready to buy at this moment, often forgetting to take that final step. Don't miss out on converting these potential customers. Let us help you turn that 48% into loyal buyers, boosting your sales and revenue like never before.":
        "تخيل أن فقط 2٪ من زوار موقعك مستعدون لإجراء عملية شراء فورية، بينما 50٪ حاليًا لا تظهر أي اهتمام بتجاه منتجك الآن. فكر في الـ 48٪ المثيرين للانتباه في المنتصف - إنهم يعبرون عن اهتمام، ولكنهم ليسوا جاهزين للشراء في هذه اللحظة، وغالبًا ما ينسون أخذ الخطوة النهائية. لا تفوت فرصة تحويل هؤلاء العملاء المحتملين. دعنا نساعدك في تحويل تلك الـ 48٪ إلى عملاء مخلصين، معززين مبيعاتك وإيراداتك كما لم يحدث من قبل."}
        </p>
        <p className='description--text2'>
        
          
          <b>{lang === 'EN'? "Give us your Email":"ادخل بريدك الإلكتروني"} </b>{lang === 'EN'? "and Let us focus on the 48%":"ودعنا نهتم بـ 48٪؜ من زوارك"}</p>
      </div>

      {/* Email */}
      <div className="email--container">
        <div>

        <div className={"email--title " + (lang !== "EN"? "email--arabic":"")} >
        {lang === 'EN'? "We Are Here to Know Your Opinion":"نحن هنا لخدمة احتياجات متجرك"}

          </div>
        <div className={"email--subtitle " + (lang !== "EN"? "email--arabic":"")}>
        {lang === 'EN'? "Please Enter Your Email":"أدخل بريدك الإلكتروني"}

          </div>
        </div>
        <form action={addEmail}  onSubmit={addEmail} >
          <input type="email" name='email' onChange={handleChange} value={email} className='email--input' placeholder={lang ==='EN'?"Enter Email Address":"أدخل بريدك الإلكتروني"} />
          <input type="submit" value={(lang ==='EN'?"Send":"أرسل")} className='send--btn' />
        </form>
      </div>

      {/* Features */}
        <h2 className="features--title">
        {lang === 'EN'? " What do we offer?":"ماذا نقدم؟"}
         </h2>
      <div className="features--container" >
        {features.map((feature, i) =>
          <div className="feature--container">
            <div className="feature--img--container" style={i === 4 ||  i === 2? { width: '60px', marginBottom: "-23px", marginLeft: '10px' } : {}}>
              <img src={feature.img} alt="" /></div>
            <h4 style={lang !== 'EN'? {fontFamily: "'Tajawal', sans-serif"} :{}} className="feature--title"><p>{feature.title[lang]}</p></h4>
            <p className="feature--text">{feature.text[lang].map(text => <li>{text}</li>)}</p>
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="footer--container">
        <p className='footer--text'>{lang === "EN" ? "© 2023 SMAC. All rights reserved.":"© 2023 SMAC.كل الحقوق محفوظة "}</p>
      </div>
{ loading ? 
<div className="loading">

      <div className='loading--container'>
      
          <img src={loadingImg} alt="" />
    
      </div>
</div>
:''}

    </div>
    : ''}</>
 )
}
